var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{style:({height: '100%', width: '100%'}),attrs:{"loading":_vm.getCategoryTreeAction,"type":"card-heading, image@1"}},[(!_vm.stepperData.all_nomenclature)?[_c('v-row',[_c('v-col',[_c('treeselect',{attrs:{"options":_vm.nomenclatureTree,"load-options":_vm.loadOptions,"auto-load-root-options":true,"placeholder":"Выберите категории и товары","value-format":"object","loading-text":"Загрузка...","auto-focus":"","search-nested":"","required":"","show-count":"","multiple":""},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var countClassName = ref.countClassName;
return [(node.raw.icon)?_c('v-icon',[_vm._v(" "+_vm._s(node.raw.icon)+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(node.label))]),(shouldShowCount && node.raw.children != null)?_c('span',{class:countClassName},[_vm._v("("+_vm._s(count)+")")]):_vm._e()]}},{key:"value-label",fn:function(ref){
var node = ref.node;
return [(node.raw.icon)?_c('v-icon',[_vm._v(" "+_vm._s(node.raw.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(node.label)+" ")]}}],null,false,3138615263),model:{value:(_vm.selectedNomenclatureItems),callback:function ($$v) {_vm.selectedNomenclatureItems=$$v},expression:"selectedNomenclatureItems"}})],1)],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }